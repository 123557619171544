import { defineStore } from "pinia";
import { type IChatItem } from "@/assets/ts/global/interfaces/chat/IChatItem";

export const useActiveChatStore = defineStore("active-chat", {
	state: (): {
		activeChat: null | IChatItem;
	} => ({
		activeChat: null,
	}),
	actions: {
		setActiveChat(chat: IChatItem) {
			this.activeChat = chat;
		},
		resetActiveChat() {
			this.activeChat = null;
		},
	},
	getters: {
		getActiveChat(): null | IChatItem {
			return this.activeChat;
		},
	},
});
