import { defineStore } from "pinia";
import type { IEventAdvanced } from "@/assets/ts/global/interfaces/event/IEventAdvanced";

export const useActiveEventStore = defineStore("active-event", {
	state: (): {
		activeEvent: null | IEventAdvanced;
	} => ({
		activeEvent: null,
	}),
	actions: {
		setActiveEvent(event: IEventAdvanced): void {
			this.activeEvent = event;
		},
		resetActiveEvent() {
			this.activeEvent = null;
		},
	},
	getters: {
		getActiveEvent(): IEventAdvanced | null {
			return this.activeEvent;
		},
		hasActiveEvent(): boolean {
			return !!this.activeEvent;
		},
		getActiveEventId(): string | undefined {
			return this.activeEvent?.id;
		},
		getEventTrigger(): boolean {
			return !!this.activeEvent?.is_org_trigger;
		},
	},
});
