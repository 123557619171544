import type { TAiResponseUpdateAll } from "@/assets/ts/entities/ai/types/TAiResponseUpdateAll";
import type { IWsResponseUpdateAll } from "assets/ts/entities/ws/interfaces/IWsResponseUpdateAll";
import { useIsActiveAgent } from "~/composables/useIsActiveAgent";

export async function wsHandleUpdateAll(response: IWsResponseUpdateAll<TAiResponseUpdateAll>) {
	const {
		chatListStore: { fetchChats },
		messagesStore: { fetchMessages },
		lastMessageStore: { readLastMessage },
	} = useStores();

	const { activeChatStore } = useStores();
	const agentId = activeChatStore.getActiveChat?.agent;

	if (agentId) {
		if (useIsActiveAgent(response.agent) || response.agents.includes(agentId)) {
			await fetchMessages();
			await readLastMessage();
		}
	}

	fetchChats();
}
