import { defineStore } from "pinia";
import type { IMessage } from "@/assets/ts/global/interfaces/IMessage";
import { $useFetch } from "~/composables/useFetch";
import { api } from "~/api/api";
import type { IApiResponsePayload } from "@/assets/ts/entities/api/interfaces/IApiResponsePayload";
import type { TFetchError } from "@/assets/ts/entities/api/types/TFetchError";
import type { AsyncData } from "#app";
import type { IApiResponseMessage } from "@/assets/ts/entities/api/interfaces/IApiResponseMessage";
import type { IMessageReadBody } from "@/assets/ts/entities/api/interfaces/request-body/IMessageReadBody";

export const useLastMessageStore = defineStore("last-message", {
	state: (): {
		lastMessage: IMessage | null;
	} => ({
		lastMessage: null,
	}),
	actions: {
		async setLastMessage(messages: IMessage[]) {
			this.lastMessage = messages.slice().reverse()[0] ?? null;
		},
		readLastMessage(): AsyncData<IApiResponsePayload<IApiResponseMessage> | null, TFetchError> | null {
			if (!this.getLastMessage) return null;

			const body: IMessageReadBody = {
				agent: this.getLastMessage.agent,
			};

			return $useFetch<IApiResponsePayload<IApiResponseMessage>, TFetchError>(api.messageRead(this.lastMessageId), {
				method: "POST",
				body,
			});
		},
	},
	getters: {
		getLastMessage(): IMessage | null {
			return this.lastMessage;
		},
		lastMessageId(): string | null {
			return this.lastMessage?.id ?? null;
		},
		lastMessageIsReaded(): string | null {
			return this.lastMessage?.readed ?? null;
		},
	},
});
