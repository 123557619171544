import { defineStore } from "pinia";
import type { IMessage } from "@/assets/ts/global/interfaces/IMessage";
import { api } from "~/api/api";
import type { IApiResponsePayload } from "@/assets/ts/entities/api/interfaces/IApiResponsePayload";
import type { IFetchError } from "ofetch";

export const useMessagesStore = defineStore("messages", {
	state: (): {
		messages: IMessage[];
	} => ({
		messages: [],
	}),
	actions: {
		async setMessages(messages: IMessage[]): Promise<void> {
			this.messages = messages;

			const {
				lastMessageStore: { setLastMessage },
			} = useStores();

			await setLastMessage(this.messages);
		},
		addMessage(message: IMessage): void {
			this.messages.push(message);
		},
		async fetchMessages() {
			const {
				activeChatStore,
				modalErrorStore: { open: showError },
			} = useStores();

			try {
				const messages = await useOFetch<IApiResponsePayload<IMessage[]>>(api.agentMessages(activeChatStore.getActiveChat?.agent), {
					method: "GET",
				});

				await this.setMessages(messages.data ?? []);
			} catch (error) {
				const fetchError = error as IFetchError;

				showError(fetchError.data?.message ?? fetchError.message ?? "Ошибка получения списка сообщений", true);
			}
		},
		clearMessages(): void {
			this.messages = [];
		},
	},
	getters: {
		getMessages(): IMessage[] {
			return this.messages;
		},
	},
});
