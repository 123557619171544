import { defineStore } from "pinia";
import type { IEvent } from "@/assets/ts/global/interfaces/event/IEvent";
import { hasAnyEventAttribute } from "~/utils/has-any-event-attribute";

export const useEventsStore = defineStore("events", {
	state: (): {
		events: IEvent[];
	} => ({
		events: [],
	}),
	actions: {
		setEvents(events: IEvent[]): void {
			this.events = events;

			this.setActiveEvent();
		},
		setActiveEvent() {
			if (!this.events.length) return;

			const {
				activeEventStore: { setActiveEvent },
			} = useStores();

			setActiveEvent({
				...this.events[0],
				number: 1,
				hasAnyAttribute: hasAnyEventAttribute(this.events[0]),
			});
		},
		resetEvents() {
			this.events = [];
		},
		updateEventRobot(activeEventId: string, robot: boolean) {
			this.events.find((event: IEvent) => {
				if (event.id === activeEventId) {
					event.robot = robot;
				} else {
					event.robot = false;
				}
			});
		},
	},
	getters: {
		getEvents(): IEvent[] {
			return this.events;
		},
		getNumberEvents(): number {
			return this.events.length;
		},
		getEventsId(): string[] {
			return this.events.map((event) => event.id);
		},
	},
});
